<script>
import moment from 'moment';

/**
 * Starter component
 */
export default {
  name: "profile-content",
  props:['user'], 
  async created(){
    this.baseUrl = process.env.VUE_APP_BASE_URL;
    if(this.user.user_role.id == 1){
        await this.$store.dispatch('student/getStudentLogins',this.user.code).then(()=>{
        }).then(()=>{}).catch( err =>{
          // errorHandler.methods.errorMessage(err);
          console.log("No items")
        });
    }
  },
  data() {
    return {
        qrCode:'',
        baseUrl:null,
    };
  },
  computed:{
    logins(){
      return this.$store.getters['student/getStudentLogs'];
    }
  },
  filters:{
    formatDate(value){
      if(value){
        return moment(String(value)).format('hh:mm A DD/MM/YYYY')
      }
    }
  }
};
</script>

<template>
    <div>
        <h5 class="mb-3 text-uppercase bg-light p-2">
            <i class="mdi mdi-account-circle mr-1 "></i> Profile Info
        </h5>
        <div class="text-center justify-content-center mb-3" v-if="this.user.code">
            <div class="auth-logo">
                <h1 class="logo-lg bg-success " style="width: 40%; margin: auto auto;border-radius: 5px;">
                    Student ID: {{ user.code }}
                </h1>
            </div>
        </div>
        <table class="table table-borderless table-sm">
        <tbody class="row">
            <tr class="col-md-6">
            <th scope="row">Name:</th>
            <td v-if="user.name">
                {{ user.name }}
            </td>
            </tr>
            <tr class="col-md-6">
            <th scope="row">Phone:</th>
            <td v-if="user.phone_number">
                {{ user.phone_number }}
            </td>
            </tr>
            <tr class="col-md-6">
            <th scope="row">Username:</th>
            <td v-if="user.user_name">
                {{ user.user_name }}
            </td>
            </tr>
            <tr class="col-md-6">
            <th scope="row">Type:</th>
            <td v-if="user.user_role">
                {{ user.user_role.name }}
            </td>
            </tr>
            <tr class="col-md-6" v-if="user.user_role.id == 1">
            <th scope="row">Student ID:</th>
            <td v-if="user.code">
                {{ user.code }}
            </td>
            </tr>
            <tr class="col-md-6" v-if="user.user_role.id == 1">
            <th scope="row">Stage:</th>
            <td v-if="user.year">
                {{ user.year.number + ' - ' + user.year.description }}
            </td>
            </tr>

            <tr class="col-md-6" v-if="user.user_role.id == 1">
            <th scope="row">Parent phone 1:</th>
            <td v-if="user.phone_no_1">
                {{ user.phone_no_1 }}
            </td>
            </tr>
            <tr class="col-md-6" v-if="user.user_role.id == 1">
            <th scope="row">Parent phone 2:</th>
            <td v-if="user.phone_no_2 ">
                {{ user.phone_no_2 }}
            </td>
            </tr>
            <tr class="col-md-6" v-if="user.user_role.id == 1">
            <th scope="row">School:</th>
            <td v-if="user.school">
                {{ user.school }}
            </td>
            </tr>
            <tr class="col-md-6" v-if="user.user_role.id == 1">
            <th scope="row">Balance:</th>
            <td v-if="user.balance || user.balance == 0">
                {{ user.balance }}
            </td>
            </tr>
        </tbody>
        </table>
        <!-- <div v-if="user.user_role.id == 1">
            <h3>Logins:</h3>
            <ul>
            <li v-for="item in logins" :key="item.id">
                - {{ item.login_stamp }} ~ {{ item.timestamp | formatDate }}
            </li>
            </ul>
        </div> -->
        <div class="text-center justify-content-center mb-3" v-if="this.user.qr_code">
            <div class="auth-logo">
                <span class="logo-lg">
                <img
                    :src="`${baseUrl}e95c2c2dd3ef780c269f75ee3286df1c418bc016/${'qr_codes'}/${this.user.qr_code}.png`"
                    alt=""
                    height="220"
                />
                </span>
            </div>
            <a class="btn btn-outline-success btn-rounded" :download="this.user.qr_code+'.png'" :href="`${baseUrl}e95c2c2dd3ef780c269f75ee3286df1c418bc016/${'qr_codes'}/${this.user.qr_code}.png`">
                <span class="mdi mdi-download">
                    Download Qrcode
                </span>
            </a>
        </div>
        <b-container class="bv-example-row bv-example-row-flex-cols">
        <b-row>
            <!-- <b-col class="col-md-2 offset-md-9 offset-0">
            <router-link to="/editMe">
                <b-button variant="outline-primary" pill>
                <i class="fas fa-pen mr-1"></i>
                Edit 
                </b-button>
            </router-link>
            </b-col> -->
            <b-col class="col-md-4 offset-md-8 offset-0 mt-2">
            <router-link to="/changeCredentials">
                <b-button variant="outline-warning" pill>
                <i class="fas fa-key mr-1"></i>
                Change Username &#38; Password
                </b-button>
            </router-link>
            </b-col>
        </b-row>
        </b-container>
        
    </div>
</template>

<style scoped>
ul {
  list-style-type: none;
}

.tab-content {
  border: none;
}

.list-group-item {
  border: none;
}

i {
  font-size: 20px;
}
</style>
