<template>
  <div class="col-lg-3 center">
    <div class="card ">
      <div class="card-body text-center">
        <img
          :src="loadImage(user.image)"
          alt="user-image"
          class="rounded-circle"
          style="max-width:150px;max-height:150px;"
        />
        <h3 class="mt-3 mb-0">{{user.name}}</h3>
        <div class="mb-0">
            <b-button to="/me/uploadPicture" class="btn btn-warning btn-rounded mt-1 mb-0">
            <i class="fas fa-image"></i>
                Upload Image
            </b-button>
        </div>
        <div class="text-left mt-3">
          <div class="table-responsive">
            <table class="table table-borderless table-sm">
              <tbody>
              <tr>
                <th scope="row">Full Name :</th>
                <td class="text-muted">{{user.name}}</td>
              </tr>
              <tr>
                <th scope="row">Phone :</th>
                <td class="text-muted" >
                  {{user.phone_number}}
                </td>
              </tr>
              <tr>
                <th scope="row">Username :</th>
                <td class="text-muted" >
                  {{user.user_name}}
                </td>
              </tr>
              <tr v-if="user.user_role.id == 1">
                <th scope="row">Balance :</th>
                <td class="text-muted" >
                  {{user.balance}}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'userSidebar',
    props: ['user'],
    methods:{
      loadImage(img){
        let path = '';
        try{
          path = `${process.env.VUE_APP_BASE_URL}e95c2c2dd3ef780c269f75ee3286df1c418bc016/${'profile_images'}/${img}`
          return path
        }catch(e){
          path = require('@/assets/images/users/avatar-1.jpg')
          return path;
        }
      },
    }
  }
</script>

<style scoped>

</style>
