<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import ProfileSidebar from "@/components/Account/profileSidebar";
import ProfileMe from "@/components/Account/profileMe";
import errorHandler from "@/helpers/errorHandler";

/**
 * Starter component
 */
export default {
  page:{
      title: 'My profile',
  },
  head() {
    return {
      title: `${this.title}`,
    };
  },
  async created(){
    await this.$store.dispatch('user/getProfile').then( async res =>{
        if(res.student_data){
          this.user = res.student_data.user;
          this.user.center = res.student_data.center;
          this.user.phone_no_1 = res.student_data.phone_no_1;
          this.user.phone_no_2 = res.student_data.phone_no_2;
          this.user.school = res.student_data.school;
          this.user.year = res.student_data.educational_year;
          this.user.qr_code = res.Qrcode;
          this.user.balance = res.student_data.balance;
          this.user.code = res.student_data.code;
        }else{
          this.user = res;
        }
    }).catch(err =>{
        errorHandler.methods.error(err);
    });
  },
  data() {
    return {
      title: "My profile",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Profile",
          href: "/me",
        },
        {
          text: "me",
          active: true,
        },
      ],
      user: null,
    };
  },
  components: {
    Layout,
    PageHeader,
    ProfileSidebar,
    ProfileMe
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <ProfileSidebar :user="user" v-if="user"/>
      <div :class="(this.user)?'col-lg-9':'col-lg-12'">
        <div class="card">
          <div class="card-body">
            <div class="tabs">
              <!-- Content of the tab -->
              <div class="tab-content">
                <div role="tabpanel" aria-hidden="false" class="tab-pane active">
                  <ProfileMe :user="user" v-if="user"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
